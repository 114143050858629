<script>
    import { removeDuplicates } from "@/utils";

    export default {
        name: "ProductColorList",
        props: {
            typeDirection: {
                default: "row",
                type: String,
            },
            currentOffer: Object,
            offers: Array,
        },
        data() {
            return {
                currentColor: this.currentOffer.color?.id,
            };
        },
        computed: {
            listColors() {
                return removeDuplicates(
                    this.offers
                        .filter((offer) => offer.color && offer.available)
                        .map((offer) => offer.color)
                );
            },
        },
        methods: {
            selectColor(id) {
                this.currentColor = id;
                this.$emit("select-color", id);
            },
            createBgStyle(codeColor) {
                return codeColor === "ffffff"
                    ? `background-color: #${codeColor}; border: 1px solid #bdbdbd;`
                    : `background-color: #${codeColor};`;
            },
        },
    };
</script>

<template>
    <div
        class="c-color-list"
        :class="{
            'c-color-list--row': typeDirection === 'row',
            'c-color-list--column': typeDirection === 'column',
        }"
    >
        <button
            class="c-color-list__item outline"
            :class="{ 'c-color-list__item--active': currentColor === color.id }"
            v-for="color in listColors"
            :key="color.id"
            @click="selectColor(color.id)"
            :tabindex="currentColor === color.id ? -1 : 0"
        >
            <small
                class="c-color-list__item-color"
                :style="createBgStyle(color.code)"
                role="img"
                :alt="color.name + ' цвет'"
            ></small>
        </button>
    </div>
</template>

<style lang="scss">
    .c-color-list {
        display: flex;
    }

    .c-color-list--row {
        .c-color-list__item {
            @include box(40px);
            margin-right: 8px;

            @include bp($bp-desktop-sm) {
                @include box(24px);
            }
        }
    }

    .c-color-list--column {
        flex-direction: column;

        .c-color-list__item {
            opacity: 0.4;

            @include box(12px);
            margin-bottom: 8px;

            @include bp($bp-desktop-sm) {
                @include box(24px);
            }
        }
    }

    .c-color-list__item {
        position: relative;
        border-radius: 3px;
        cursor: pointer;
        transition: 0.2s ease transform, 0.2s ease background-color, 0.2s ease opacity;

        &:hover {
            transform: scale(1.05);
        }
    }

    .c-color-list__item-color {
        @include center-absolute(0);
        border-radius: inherit;
    }

    .c-color-list__item--active {
        pointer-events: none;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            @include box(calc(100% + 4px));
            margin: -2px;
            border: 1px solid var(--primary);
            border-radius: 4px;
        }

        &:hover {
            transform: scale(1);
        }
    }
</style>
