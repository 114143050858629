<script>
    import { mapActions, mapGetters } from "vuex";
    import { apiUrl, removeDuplicates } from "@/utils";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import ColorList from "@tb/components/ProductColorList";

    export default {
        name: "ProductCard",
        components: {
            TheSvgIcon,
            ColorList,
        },
        props: {
            goods: Object,
            position: Number,
            isSearch: Boolean,
        },
        data() {
            // временный костыль на отсутсвие оферов
            let hide = true;

            let offer = this.goods.offers.filter(
                (offer) => offer.available && offer.quantity > 0
            )[0];

            if (offer === undefined) {
                hide = false;
            }

            return {
                currentOffer: offer,
                isHide: hide,
                currentOfferId: (offer || {}).id || "",
                scrImage: ((offer || {}).previewImage || {}).medium || "",
                price: (offer || {}).price || "",
                discountPrice: (offer || {}).discountPrice || "",
                discountPercent: (offer || {}).discountPercent || "",
                inCart: this.goods.inCart || "",
                loading: false,
            };
        },
        computed: {
            ...mapGetters("Catalog", ["mainSection"]),

            listColors() {
                return removeDuplicates(
                    this.goods.offers
                        .filter(
                            (goods) => goods.color && goods.available && goods.quantity > 0
                        )
                        .map((goods) => goods.color)
                );
            },

            isSale() {
                return this.discountPercent;
            },
        },
        methods: {
            ...mapActions("Root", ["addProductInCart"]),

            async addCart() {
                if (this.inCart) {
                    this.$router.push({ name: "Order" });
                    return;
                }
                this.loading = true;
                this.addProductInCart({
                    quantity: 1,
                    productId: this.currentOfferId,
                }).then(() => {
                    this.loading = false;
                    this.inCart = true;

                    sendAnalytics.send(
                        sendAnalytics.events.addToCart,
                        {
                            name: this.goods.name,
                            offerId: this.currentOfferId,
                            price: this.discountPrice,
                        },
                        {
                            mainSection: this.mainSection,
                        }
                    );
                });
            },

            selectColor(idColor) {
                let { previewImage, id, price, discountPrice } = this.goods.offers.filter(
                    (goods) => goods.color.id === idColor
                )[0];
                this.currentOfferId = id;
                this.scrImage = previewImage.original;
                this.price = price;
                this.discountPrice = discountPrice;
                this.inCart = false;
            },

            apiUrl,
            sendDataLayerEvent() {
                sendAnalytics.send(
                    sendAnalytics.events.productClick,
                    {
                        name: this.goods.name,
                        offerId: this.currentOfferId,
                        price: this.discountPrice,
                        position: this.position,
                    },
                    {
                        type: this.isSearch ? "search-list" : "",
                        mainSection: this.mainSection,
                    }
                );
            },

            // sendDataLayerEvent({ type }) {
            //     let { color, size } = this.goods.offers.filter(
            //         (offer) => offer.id === this.currentOfferId
            //     )[0];
            //
            //     try {
            //         if (type === "cart") {
            //             // window.dataLayer.push({
            //             //     event: "addToCart",
            //             //     ecommerce: {
            //             //         currencyCode: "RUB",
            //             //         add: {
            //             //             products: [
            //             //                 getProductForEC({
            //             //                     name: this.goods.name,
            //             //                     id: this.currentOfferId,
            //             //                     price: this.discountPrice,
            //             //                     mainCategory: this.goods.mainCategory,
            //             //                     mainSection: this.mainSection,
            //             //                     color,
            //             //                     size,
            //             //                     quantity: 1,
            //             //                 }),
            //             //             ],
            //             //         },
            //             //     },
            //             // });
            //         }
            //
            //         if (type === "click") {
            //             // window.dataLayer.push({
            //             //     event: "productClick",
            //             //     ecommerce: {
            //             //         click: {
            //             //             actionField: {
            //             //                 list: this.isSearch ? "search-list" : "catalog-list", //search-list
            //             //             },
            //             //             products: [
            //             //                 getProductForEC({
            //             //                     name: this.goods.name,
            //             //                     id: this.currentOfferId,
            //             //                     price: this.discountPrice,
            //             //                     mainCategory: this.goods.mainCategory,
            //             //                     mainSection: this.mainSection,
            //             //                     color,
            //             //                     size,
            //             //                     list: this.isSearch
            //             //                         ? "search-list"
            //             //                         : "catalog-list",
            //             //                     position: this.position,
            //             //                 }),
            //             //             ],
            //             //         },
            //             //     },
            //             // });
            //         }
            //     } catch (e) {}
            // },
        },
    };
</script>

<template>
    <div
        v-if="isHide"
        class="goods-card"
        :class="{ 'goods-card--in-cart': inCart, 'goods-card--loading': loading }"
    >
        <div class="goods-card__img">
            <router-link
                @click.native.prevent="sendDataLayerEvent"
                :to="{
                    name: 'Product',
                    params: { slug: goods.slug },
                }"
                class="goods-card__img-link"
                tabindex="-1"
            >
                <img :src="apiUrl(scrImage)" alt="" />
            </router-link>

            <div v-if="isSale" class="goods-card__labels">
                <div class="goods-card__label goods-card__label--sale">
                    -{{ discountPercent }}%
                </div>
            </div>
            <div v-if="listColors.length > 0" class="goods-card__list-color">
                <color-list
                    type-direction="column"
                    @select-color="selectColor"
                    :offers="goods.offers"
                    :current-offer="currentOffer"
                ></color-list>
            </div>
        </div>
        <router-link
            @click.native.prevent="sendDataLayerEvent"
            :to="{
                name: 'Product',
                params: { slug: goods.slug },
            }"
            class="goods-card__name"
        >
            {{ goods.shortName ? goods.shortName : goods.name }}
        </router-link>
        <div class="goods-card__options">
            <div class="goods-card__price">
                <div
                    class="goods-card__price-num goods-card__price-num--current"
                    :class="{ 'goods-card__price-num--sale': isSale }"
                >
                    {{ discountPrice | formatPrice }}₽
                </div>
                <div v-if="isSale" class="goods-card__price-num goods-card__price-num--old">
                    {{ price | formatPrice }}₽
                </div>
            </div>
            <button
                class="goods-card__btn outline"
                :class="{ 'goods-card__btn--loading': loading }"
                @click="addCart"
            >
                <preloader :show="loading" sm-size></preloader>
                <the-svg-icon v-if="inCart" name="check" :size-w="24"></the-svg-icon>
                <the-svg-icon v-else name="add_cart" :size-w="24"></the-svg-icon>
                <span>В корзину</span>
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    .goods-card {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff;
        height: auto;

        @include bp($bp-desktop-sm-for-card) {
            padding: 24px;

            &:after {
                content: "";
                @include center-absolute(0);
                border: 1px solid var(--primary-border--light);
                opacity: 0;
                transition: 0.2s ease-in-out all;
            }
        }

        &:hover,
        &:focus-within {
            .goods-card__name {
                color: var(--primary);
            }

            .goods-card__btn {
                opacity: 1;
            }

            .c-color-list__item {
                opacity: 1;
            }

            &:after {
                opacity: 1;
            }
        }
    }

    .goods-card--in-cart {
        .goods-card__btn {
            opacity: 1;
            background-color: var(--primary);
            color: #fff;
        }
    }

    .goods-card--loading {
        .goods-card__btn {
            opacity: 1;
        }
    }

    .goods-card__img {
        position: relative;
        padding-right: 18px;
        z-index: 2;
    }

    .goods-card__img-link {
        position: relative;
        display: block;
        padding-bottom: 100%;
        outline: none;

        img {
            @include center-absolute(0);
            margin: auto;
            max-height: 100%;
        }
    }

    .goods-card__labels {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    .goods-card__label {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        border-radius: 3px;
        padding: 3px 4px;
    }

    .goods-card__label--sale {
        background-color: var(--danger-text--light);
        color: #fff;
    }

    .goods-card__list-color {
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 2;
    }

    .goods-card__color {
        position: relative;
        @include box(12px);
        border-radius: 3px;
        background-color: red;
        margin-bottom: 4px;
        cursor: pointer;
        transition: 0.2s ease all;

        &:hover {
            transform: scale(1.05);
        }

        @include bp($bp-desktop-sm) {
            @include box(18px);
            margin-bottom: 8px;
        }
    }

    .goods-card__color--active {
        &:after {
            content: "";
            position: absolute;
            @include box(calc(100% + 4px));
            margin: -2px;
            border: 1px solid var(--primary);
            border-radius: 4px;
        }
    }

    .goods-card__name {
        display: block;
        position: relative;
        z-index: 2;
        font-size: 13px;
        line-height: 16px;
        color: var(--secondary-text);
        margin-bottom: 8px;
        min-height: 48px;
        padding-right: 10px;
        transition: 0.3s ease-in-out color;

        @include bp($bp-desktop-sm) {
            font-size: 14px;
            line-height: 20px;
            min-height: 40px;
            padding-right: 36px;
            outline-offset: 3px;
        }
    }

    .goods-card__options {
        margin-top: auto;
        position: relative;
        z-index: 2;

        @include bp($bp-tablet-sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .goods-card__price {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-bottom: 4px;

        @include bp($bp-tablet-sm) {
            margin-bottom: 0;
        }
    }

    .goods-card__price-num {
        font-weight: 600;
        font-size: 22px;
        line-height: 24px;
    }

    .goods-card__price-num--current {
        margin-right: 8px;
    }

    .goods-card__price-num--old {
        font-size: 15px;
        line-height: 16px;
        font-weight: 500;
        color: var(--secondary-text);
        text-decoration: line-through;
        margin-bottom: 3px;
    }

    .goods-card__price-num--sale {
        color: var(--danger-text--light);
    }

    .goods-card__btn {
        position: relative;
        padding: 8px;
        background-color: var(--secondary);
        border-radius: 3px;
        outline: none;
        font-size: 13px;
        line-height: 14px;

        @include bp($bp-desktop-sm) {
            transition: 0.2s ease-in-out background-color, 0.2s ease-in-out opacity;
            opacity: 0;
        }

        svg {
            display: none;

            @include bp($bp-tablet-sm) {
                display: flex;
            }
        }

        & > span {
            @include bp($bp-tablet-sm) {
                display: none;
            }
        }
    }

    .goods-card__btn--loading {
        color: transparent;
        svg {
            opacity: 0;
        }
    }
</style>
