<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import { metaInfo } from "@/core/MetaInfo";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import GoodsCardItem from "@tb/components/ProductCard";

    export default {
        name: "Search",
        components: {
            GoodsCardItem,
        },
        props: {
            query: String,
        },
        computed: {
            ...mapState("Search", ["searchItems", "loading"]),
            ...mapGetters("Catalog", ["mainSection"]),
        },
        watch: {
            searchItems: {
                handler(updatedItems) {
                    if (
                        process.env.VUE_APP_ENV === "client" &&
                        Object.keys(updatedItems).length
                    ) {
                        sendAnalytics.send(sendAnalytics.events.productList, updatedItems, {
                            type: "search-list",
                            mainSection: this.mainSection,
                        });
                    }
                },
                immediate: true,
            },
        },
        serverPrefetch() {
            return this.getSearch({ term: this.$route.query.term });
        },
        async mounted() {
            if (!this.searchItems.length) {
                await this.getSearch({ term: this.$route.query.term });
            }
        },
        methods: {
            ...mapActions("Search", ["getSearch", "clearCatalog"]),
        },
        metaInfo() {
            return metaInfo.getMeta("search");
        },
    };
</script>

<template>
    <div class="l-container">
        <div class="b-search">
            <div class="b-search__wrap" :class="{ 'b-search__wrap--loading': loading }">
                <goods-card-item
                    is-search
                    :goods="goods"
                    v-for="goods in searchItems"
                    :key="goods.id"
                ></goods-card-item>
            </div>
            <preloader :show="loading" lg-size></preloader>
            <div v-if="searchItems.length === 0 && !loading" class="b-search__empty">
                Отсутсвуют элементы
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .b-search {
        padding: 32px 0;
    }

    .b-search__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -12px;

        .goods-card {
            width: calc(100% / 2 - 24px);
            margin: 0 12px;
            margin-bottom: 24px;

            @include bp($bp-tablet-sm) {
                width: calc(100% / 3 - 24px);
            }
            @include bp($bp-desktop-sm) {
                width: calc(100% / 4 - 24px);
            }
        }
    }

    .b-search__wrap--loading {
        opacity: 0.3;
    }
</style>
